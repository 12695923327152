import { Pipe, PipeTransform } from '@angular/core';

const prefix = 'data:image/png;base64,';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(value: string | ArrayBuffer | ArrayBuffer[]): string | string[] {
    if (typeof value === 'string') {
      // If value is a string, prepend the prefix if it's not already present
      return value.includes(prefix) ? value : `${prefix}${value}`;
    }

    if (Array.isArray(value)) {
      // If value is an array of ArrayBuffers, convert each one to a Base64 string
      return value.map((buffer) => this.arrayBufferToBase64WithPrefix(buffer));
    }

    // Handle the case where value is a single ArrayBuffer
    return this.arrayBufferToBase64WithPrefix(value);
  }

  private arrayBufferToBase64WithPrefix(buffer: ArrayBuffer): string {
    const base64String = this.arrayBufferToBase64(buffer);
    return `${prefix}${base64String}`;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
