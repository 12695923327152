import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gea-hrt-history-chart-toggle',
  templateUrl: './toggles.component.html',
  styleUrls: ['./toggles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedOperatingDataTogglesComponent {
  selection: string[] = [];

  @Output() updateSelection = new EventEmitter<string[]>();

  @Input({ required: true }) switches!: [string, boolean][];

  @Input({ required: true }) set defaultSelection(value: string[]) {
    this.selection = value;
  }

  trackByIndex(_: number, item: [string, boolean]): string {
    return item[0];
  }

  toggleSwitch(key: string) {
    const { selection } = this;
    const index = selection.indexOf(key);

    index === -1 ? selection.push(key) : selection.splice(index, 1);

    this.updateSelection.emit(selection);
  }
}
