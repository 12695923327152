<div class="fault-recommendations-dialog-container">
  <div mat-dialog-title class="recommendation-dialog-title">
    <gea-hrt-heading class="dialog-title" type="sub">
      <p *ngIf="data?.recommendation; else NewRecommendation">
        {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.UPDATE' | translate: { actionName: data?.recommendation?.actionName } }}
      </p>
      <ng-template #NewRecommendation>New Recommendation</ng-template>
    </gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
  </div>
  <mat-dialog-content>
    <div class="input">
      <geaui-textfield-v2 [id]="'recommendation'" [required]="false" [group]="formGroup"></geaui-textfield-v2>
    </div>

    <div class="image">
      <gea-hrt-images-preview
        [images]="data?.recommendation?.images ?? []"
        (deleteImage)="deleteImage($event)"
        (addImage)="addMoreImages()"
        (fileSelected)="onFileSelected($event)" />
    </div>

    <div class="buttons">
      <geaui-button-v2 type="secondary" class="cancel" data-testid="button-cancel" (click)="closeDialog()">
        {{ 'UI-LIB.GENERAL.CANCEL' | translate }}
      </geaui-button-v2>
      <geaui-button-v2 type="primary" class="save" data-testid="button-save" [disabled]="!formGroup.valid" (click)="save()">
        {{ 'UI-LIB.GENERAL.SAVE' | translate }}
      </geaui-button-v2>
    </div>
  </mat-dialog-content>
</div>
