import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemHealthConnectivity } from '@shared/models';

@Component({
  selector: 'gea-hrt-connectivity-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<span
    class="status {{ connectivity?.signalStatus?.isAvailable ? 'available' : 'unavailable' }}"
    [pTooltip]="text"></span> `,
  styles: `
    .status {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: block;
    }
    .unavailable {
      background-color: red;
    }
    .available {
      background-color: green;
    }
  `,
})
export class ConnectivityStatusComponent {
  @Input() connectivity?: SystemHealthConnectivity | undefined;

  _text = '';

  get text() {
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    if (this.connectivity === undefined) return '';
    const timeString =
      this.connectivity.signalStatus.lastSignal.toString() == '0001-01-01T00:00:00'
        ? 'No Signals'
        : this.connectivity.signalStatus.lastSignal.toLocaleString(undefined, options);

    return `Last Signal: ${timeString} 
            Data received: ${this.connectivity.signalStatus.receivedPercentage.toFixed(2)}%`;
  }
}
