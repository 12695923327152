import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gea-hrt-images-preview',
  templateUrl: './images-preview.component.html',
  styleUrl: './images-preview.component.scss',
})
export class ImagesPreviewComponent {
  @Input({ required: true }) images: (string | ArrayBuffer[])[] = [];

  @Output() deleteImage = new EventEmitter<number>();
  @Output() addImage = new EventEmitter<number>();
  @Output() fileSelected = new EventEmitter<Event>();

  trackByIndex(index: number): number {
    return index;
  }

  onDeleteImage(index: number) {
    this.deleteImage.emit(index);
  }

  onAddImage() {
    this.addImage.emit();
  }

  onFileSelected(files: Event) {
    this.fileSelected.emit(files);
  }
}
