/**
 * asks for a distinct list of machines,
 * that are available for the customer
 */
export class FetchMachines {
  static readonly type = '[FaultAnalysis] Fetch Machines';
}

/**
 * sets an array of machines as selected.
 * Subset of the whole list of machines.
 */
export class SetSelectedMachines {
  static readonly type = '[FaultAnalysis] Set Selected Machines';
  constructor(public machines: number[]) {}
}

export class ClearMachines {
  static readonly type = '[FaultAnalysis] Clear Machines';
}

export class SetMachineOverviewData {
  static readonly type = '[FaultAnalysis] Set Machine Overview Data';
}
