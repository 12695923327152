import { Component, Input } from '@angular/core';

@Component({
  selector: 'gea-hrt-update-time',
  template: `<span [title]="date | date: 'd/MM/YYYY - HH:mm zzzz'">
    {{ date | date: 'dd/MM' }}
  </span> `,
})
export class UpdateTimeComponent {
  @Input({ required: true }) date!: string | Date;
}
