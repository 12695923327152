import { Component } from '@angular/core';
import { ResolvedFault } from '@app/shared/models';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-resolved-fault-status-renderer',
  template: `
    <div [class]="rowData.status">
      {{ rowData.status }}
    </div>
  `,
  styles: `
    div {
      text-align: center;
      color: white;
      padding: 0.4rem;
      background-color: var(--geaui-light-gray);
      &.open {
        color: var(--geaui-yellow);
      }
      &.useful {
        color: var(--geaui-light-green);
      }
      &.discarded {
        color: var(--geaui-light-red);
      }
    }
  `,
})
export class ResolvedFaultStatusRendererComponent implements ColumnRendererComponent<ResolvedFault> {
  rowData!: ResolvedFault;
}
