import { FormGroup } from '@angular/forms';
import { LayoutService, SelectOption } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

export abstract class FiltersBaseComponentComponent {
  /**
   * these classes will work for most forms with just a few fields
   */
  public simpleFormFieldClasses: string[] = [
    'mdc-layout-grid__cell',
    'mdc-layout-grid__cell--span-2',
    'mdc-layout-grid__cell--span-8-phone_p',
    'mdc-layout-grid__cell--span-2-phone_l',
    'mdc-layout-grid__cell--span-2-desktop_xl',
  ];
  public dateRangeTranslations = [
    'FAULT_ANALYSIS.FILTERS.DATE_RANGE_7_DAYS',
    'FAULT_ANALYSIS.FILTERS.DATE_RANGE_2_WEEKS',
    'FAULT_ANALYSIS.FILTERS.DATE_RANGE_3_WEEKS',
    'FAULT_ANALYSIS.FILTERS.DATE_RANGE_4_WEEKS',
  ];
  public dateRangeOptions: SelectOption<number>[] = [
    { name: '', value: 7 },
    { name: '', value: 14 },
    { name: '', value: 21 },
    { name: '', value: 28 },
  ];
  public statusTranslations = ['FAULT_ANALYSIS.PENDING', 'FAULT_ANALYSIS.SOLVED'];
  public statusOptions: SelectOption<string>[] = [
    { name: '', value: 'PENDING' },
    { name: '', value: 'SOLVED' },
  ];
  public ngDestroyed$ = new Subject<void>();

  public formGroup!: FormGroup;

  constructor(
    readonly layout: LayoutService,
    readonly translate: TranslateService
  ) {}

  protected initTranslations(): void {
    this.translate.onLangChange.pipe(takeUntil(this.ngDestroyed$)).subscribe(() => {
      this.translateOptions();
    });
  }

  translateOptions(): void {
    this.dateRangeOptions = this.dateRangeOptions.map((option, index) => ({
      ...option,
      name: this.translate?.instant(this.dateRangeTranslations[index]),
    }));
    this.statusOptions = this.statusOptions.map((option, index) => ({
      ...option,
      name: this.translate?.instant(this.statusTranslations[index]),
    }));
  }

  isSelectOption(obj: unknown): obj is SelectOption<number> {
    return (obj as SelectOption<number>).name !== undefined && (obj as SelectOption<number>).value !== undefined;
  }
}
