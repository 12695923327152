import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toTitle',
})
export class ToTitlePipe implements PipeTransform {
  private replacements: { [key: string]: string } = {
    ipc_loop_time: 'Loop Time Information',
    discharge_temperature_health: 'Discharge Temperature',
    bearing_heath_check: 'Bearing Health',
    starts_and_runtime_jumps: 'Compressor Starts Information',
    cpu: 'CPU',
  };

  transform(value: string): string {
    if (!value) {
      return value;
    }

    return (
      this.replacements[value] ||
      value
        .split('_')
        .map((word) => {
          const lowerCaseWord = word.toLowerCase();
          return this.replacements[lowerCaseWord] || this.capitalize(word);
        })
        .join(' ')
    );
  }

  private capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
}
