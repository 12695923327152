import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RecommendationDto } from '@app/shared/models';

@Component({
  selector: 'gea-hrt-recommendation-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class RecommendationDialogComponent {
  formGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RecommendationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: { recommendation?: RecommendationDto }
  ) {
    this.formGroup = new FormGroup({
      recommendation: new FormControl(data?.recommendation?.actionName ?? '', [Validators.minLength(10), Validators.required]),
      images: new FormControl<(string | ArrayBuffer[])[]>(this.data?.recommendation?.images ?? []),
    });
  }

  save() {
    const value = this.formGroup.value;
    const actionName = value.recommendation;
    const images = value.images ?? [];

    this.dialogRef.close({
      actionName,
      images,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    Array.from(inputElement?.files ?? []).forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const imagesControl = this.formGroup.get('images');

        if (!imagesControl) {
          return;
        }

        const images = imagesControl.value;

        imagesControl.setValue([...images, reader.result]);
      };

      reader.readAsDataURL(file);
    });
  }

  addMoreImages() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  deleteImage(index: number) {
    const imagesControl = this.formGroup.get('images') as FormControl;

    if (imagesControl) {
      const images = imagesControl.value as string[];

      if (images && index >= 0 && index < images.length) {
        const updatedImages = [...images]; // Create a shallow copy of the array
        updatedImages.splice(index, 1);
        imagesControl.setValue(updatedImages);
      }
    }

    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
}
