import { MatIconRegistry } from '@angular/material/icon';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { Store } from '@ngxs/store';
import { ImageService, SetEnvironment, UserState } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';
import { FetchMemberships } from '@shared/state';

@Component({
  selector: 'gea-hrt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  contentReady = false;
  noAuth = true;
  firefox = false;

  constructor(
    readonly title: Title,
    readonly translate: TranslateService,
    readonly store: Store,
    readonly iconRegistry: MatIconRegistry,
    readonly imageService: ImageService,
    readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.firefox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    this.store.select(UserState.token).subscribe((token) => {
      this.contentReady = token !== '';
      if (this.contentReady) {
        this.store.dispatch(new FetchMemberships());
      }
    });
    if (environment.name) {
      this.store.dispatch(new SetEnvironment(environment.name));
    }

    this.translate.setDefaultLang('en-US');
    this.translate.get('GENERAL.TITLE').subscribe((appTitle: string) => {
      this.title.setTitle(appTitle);
    });
  }
}
