import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RecommendationDto } from '@shared/models';
import { SelectOption } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-problem-solve-dialog',
  templateUrl: './solve-dialog.component.html',
  styleUrls: ['./solve-dialog.component.scss'],
})
export class ProblemSolveDialogComponent implements OnInit {
  constructor(readonly dialogRef: MatDialogRef<ProblemSolveDialogComponent>) {
    this.formGroup = new FormGroup({
      additionalNote: new FormControl(''),
      selectedRecommendations: new FormControl([]),
      images: new FormControl<(string | ArrayBuffer[])[]>([]),
    });
  }

  @Input({ required: true }) recommendations: RecommendationDto[] = [];

  formGroup: FormGroup;
  recommendationsOptions: SelectOption[] = [];
  selectedRecommendations: SelectOption[] = [];

  noneOfTheAbove = false;

  ngOnInit() {
    this.recommendations.forEach(({ id: value, actionName: name, status }) => {
      this.recommendationsOptions.push({ value, name });

      if (status === 2) {
        this.selectedRecommendations.push({ name, value });
      }
    });

    if (this.recommendationsOptions.length === 0) {
      this.noneOfTheAbove = true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    const selectedRecommendations: SelectOption[] = this.formGroup.controls['selectedRecommendations'].value;

    if (selectedRecommendations) {
      const values = selectedRecommendations.map((option) => option.value);
      this.formGroup.controls['selectedRecommendations'].setValue(values);
    }

    if (this.dialogRef) {
      this.dialogRef.close(this.formGroup.value);
    }
  }

  toggleSelection(input: boolean) {
    this.noneOfTheAbove = input;
    if (this.noneOfTheAbove) {
      this.selectedRecommendations = [];
    }
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    Array.from(inputElement?.files ?? []).forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const imagesControl = this.formGroup.get('images');

        if (!imagesControl) {
          return;
        }

        const images = imagesControl.value;

        imagesControl.setValue([...images, reader.result]);
      };

      reader.readAsDataURL(file);
    });
  }

  deleteImage(index: number) {
    const imagesControl = this.formGroup.get('images') as FormControl;

    if (imagesControl) {
      const images = imagesControl.value as string[];

      if (images && index >= 0 && index < images.length) {
        const updatedImages = [...images]; // Create a shallow copy of the array
        updatedImages.splice(index, 1);
        imagesControl.setValue(updatedImages);
      }
    }

    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  addMoreImages() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
}
